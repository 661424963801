<template>
    <ValidationObserver ref="form" v-slot="{ handleSubmit }">
        <div class="page-wrapper login">
            <div class="page-inner bg-brand-gradient">
                <div class="page-content-wrapper bg-transparent m-0">
                    <div class="height-10 w-100 shadow-lg px-4 bg-brand-gradient">
                        <div class="d-flex align-items-center container p-0">
                            <div class="page-logo width-mobile-auto m-0 align-items-center justify-content-center p-0 bg-transparent bg-img-none shadow-0 height-9">
                                <a href="javascript:void(0)"
                                   class="page-logo-link press-scale-down d-flex align-items-center">
                                    <img :src="publicPath + 'media/birthday/logo_birthday_2023.png'" class="logo-edutalk" alt="Edutalk Tư Vấn"
                                         aria-roledescription="logo">
                                    <span class="page-logo-text mr-1"></span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="flex-1" style="background: url(media/svg/pattern-1.svg) no-repeat center bottom fixed; background-size: cover;">
                        <div class="container py-4 py-lg-5 my-lg-5 px-4 px-sm-0">
                            <div class="row">
                                <div class="col-xl-12">
                                    <h2 class="fs-xxl fw-500 mb-5 text-white text-center">
                                        Đăng ký tài khoản nhân sự

                                    </h2>
                                </div>
                                <div class="col-xl-6 ml-auto mr-auto">
                                    <div class="card p-4 rounded-plus bg-faded">
                                        <div v-if="messages.successRegister" class="alert alert-primary text-dark" role="alert">
                                            <strong>Heads Up!</strong> Due to server maintenance from 9:30GTA to 12GTA, the verification emails could be delayed by up to 10 minutes.
                                        </div>
                                        <form id="js-login" class="form" @submit.prevent="handleSubmit(onSubmit)"  autocomplete="off">
                                            <div class="form-group">
                                                <label class="form-label" for="emailverify">Email</label>
                                                <ValidationProvider vid="email" name="Email" rules="required" v-slot="{ errors, classes }">
                                                    <input type="email" ref="email" name="email" id="emailverify"
                                                           :class="classes" class="form-control"
                                                           placeholder="Nhập email của bạn"
                                                           v-model=form.email>
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group">
                                                <label class="form-label" for="password">Mật khẩu</label>
                                                <ValidationProvider vid="password" name="Mật khẩu" rules="required|min:6"
                                                                    v-slot="{ errors, classes }">
                                                    <input id="password" type="password" ref="password" name="password" class="form-control"
                                                           :class="classes"
                                                           placeholder="Mật khẩu phải ít nhất 6 ký tự"
                                                           v-model=form.password>
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="form-group">
                                                <label class="form-label" for="confirm_password">Xác nhận mật khẩu</label>
                                                <ValidationProvider name="Xác nhận mật khẩu" rules="required|min:6|confirmed:password"
                                                                    v-slot="{ errors, classes }">
                                                    <input id="confirm_password" type="password" ref="confirm_password" name="confirm_password" class="form-control"
                                                           :class="classes"
                                                           placeholder="Xác nhận mật khẩu"
                                                           v-model=form.confirm_password>
                                                    <div class="fv-plugins-message-container">
                                                        <div data-field="name" data-validator="notEmpty"
                                                             class="fv-help-block">{{ errors[0] }}
                                                        </div>
                                                    </div>
                                                </ValidationProvider>
                                            </div>
                                            <div class="row no-gutters">
                                                <div class="col-md-4 ml-auto text-right">
                                                    <button id="js-login-btn" class="btn btn-block btn-danger btn-xl waves-effect waves-themed">
                                                      <i v-if="loading" class="el-icon-loading"></i>
                                                      Đăng ký ngay</button>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 mt-3 text-center justify-content-edutalk ">Bạn đã có tài khoản?
                                                <router-link to="/login" v-slot="{ href, navigate }">
                                                    <a :href="href"  title="Đăng ký tài khoản"
                                                       @click="navigate"
                                                       data-filter-tags="Đăng ký tài khoản"
                                                       class=" waves-effect waves-themed">
                                                        Đăng nhập ngay
                                                    </a>
                                                </router-link>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="position-absolute pos-bottom pos-left pos-right p-3 text-center text-white">
                                2021 © Edutalk by&nbsp;<a href="https://edutalk.edu.vn"
                                                          class="text-white opacity-40 fw-500" title="edutalk.edu.vn"
                                                          target="_blank">edutalk.edu.vn</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ValidationObserver>
</template>
<!-- Load login custom page styles -->

<script>
    import {REGISTER} from "../../../core/services/store/auth.module";

    export default {
      name: "edutalk-register",
        data() {
            return {
                publicPath: process.env.VUE_APP_BASE_URL,
                messages: {
                    successRegister: null
                },
                form: {
                    email: "",
                    password: "",
                    confirm_password: "",
                },
                check_error: false,
                loading: false
            };
        },
        computed: {
        },
        methods: {
            onSubmit() {
              this.loading = true;
               this.$store
              .dispatch(REGISTER, this.form)
              .then((res) => {
                if(!res.error){
                  this.$router.push({ path: `update-user/${res.data.id}` }).then(() => {
                    this.$message({
                      type: 'success',
                      message: res.message,
                      showClose: true
                    })
                  });
                }
              })
              .catch((res) => {
                if (res.data?.data?.message_validate_form){
                  this.$refs.form.setErrors(res.data.data.message_validate_form);
                }
              }).finally(() => {
                this.loading = false;
               });
            },

        }
    };
</script>
